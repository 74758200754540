<template>
  <section class="orders content-wrapper">
    <div class="page-header">
      <h3 class="page-title"> Commande </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">e-Commerce</a></li>
          <li class="breadcrumb-item active" aria-current="page">commande</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Commande</h4>
            <div class="row">
              <div class="col-md-4 offset-md-8">
                <b-input-group>
                      <b-form-input placeholder="Recherche" v-model="search"></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="row overflow-auto">
              <div class="col-12">
                <b-table 
                :items="items" 
                id="table-list" 
                responsive :per-page="perPage" 
                :current-page="currentPage" 
                :fields="fields" 
                :sort-by.sync="sortBy" 
                :sort-desc.sync="sortDesc"
                :filter="search">

              <template #cell(client)="row">
                <span>{{row.item.content.buyer.name}}</span>
              </template>

              <template #cell(dateorder)="row">
                <span>{{row.item.content.dateorder}}</span>
              </template>

              <template #cell(datedelivery)="row">
                <span>{{row.item.content.datedelivery}}</span>
              </template>

              <template #cell(status)="row">
                <span class="badge badge-primary" v-if="row.item.status==='preparation'">Préparation</span>
              </template>

              <!-- <template v-slot:cell(action)="data">
                <span v-html="data.value"></span>
              </template> -->
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios= require ('axios').default
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
});

export default {
  data: function() {
    return {
      perPage: 10,
      sortBy: 'name',
        currentPage: 1,
        sortDesc: false,
        sortByFormatted: true,
        filterByFormatted: true,
        sortable: true,
        search:'',
        fields: [
          { key: 'slugname', sortable: true },
          { key: 'client', label:'client', sortable: true },
          { key: 'dateorder', label:'date de commande', sortable: true },
          { key: 'datedelivery', label:'date de livraison', sortable: true },
          { key: 'status', sortable: true },
          // { key: 'action', sortable: true }
        ],
        items: [
        ]
    };
  },
  computed: {
      rows() {
        return this.items.length
      }
    },
    methods: {
      getPurchaseOrders() {
        axios.get('mypurchaseorders', {
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem('token')
            }
        })
        .then(respurchaseOrders=> this.items= respurchaseOrders.data.result)
        .catch(errPurchaseOrders=> console.log(errPurchaseOrders)) 
        }
    },
  async created () {
    this.getPurchaseOrders()
  }
}
</script>